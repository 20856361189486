import React from 'react';
import styled from 'styled-components';
import {Link, useStaticQuery, graphql} from 'gatsby';
import CopyRight from './CopyRight';
import Logo from './Logo';
import Vca from "./VcaLogo";

const Footer = styled.div`
  width: 100%;
  background-color: var(--primary-color);
  color: #202020;
  padding: var(--spacing-xl);
  font-weight: 300;
`;

const FooterColumns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
`;

const FooterColumnLogo = styled.div`
  flex-basis: 25%;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    display: none;
  }
`;
const FooterColumn = styled.div`
  flex-basis: 25%;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    flex-basis: 100%;
    margin-bottom: var(--spacing-m);
    padding-bottom: var(--spacing-m);
    border-bottom: 1px solid var(--primary-color);
  }

  .footerLink {
    display: block;
    color: #FFF;
    font-size: 0.9rem;
    font-family: var(--font-small);
    line-height: 1.4;
    text-decoration: none;
    margin-bottom: var(--spacing-s);
  }
`;

const FooterLine = styled.div`
  color: #FFF;
  font-size: 0.9rem;
  font-family: var(--font-small);
  line-height: 1.4;

  a {
    color: #FFF;
    text-decoration: none;
  }
`;

const FooterLineWithSpace = styled(FooterLine)`
  margin-top: var(--spacing-m);
`;

const settingToValueMap = (settings) => {
  return settings.reduce((settingsKeyValuePair, setting) => {
    settingsKeyValuePair[setting.title] = {
      id: setting.id,
      value: setting.value
    }
    return settingsKeyValuePair;
  }, {});
}

export default function ContactFooter() {
  const { settings } = useStaticQuery(graphql`
        query allSettings {
            settings: allSanitySettings {
              nodes {
                title
                value
                id
              }
            }
        }
  `);
  const settingsPairs = settingToValueMap(settings.nodes);

  return (
    <Footer>
      <FooterColumns>
        <FooterColumnLogo>
          <Link to={'/'} title={'Home'}>
            <Logo />
          </Link>
        </FooterColumnLogo>
        <FooterColumnLogo>
          <Vca />
        </FooterColumnLogo>
        <FooterColumn>
          <Link className="footerLink" to="/het-bedrijf" title="Het bedrijf">
            Het bedrijf
          </Link>
          <Link
            className="footerLink"
            to="/projecten"
            title="Projecten"
          >
            Projecten
          </Link>
          <Link className="footerLink" to="/contact" title="Contact">
            Contact
          </Link>
        </FooterColumn>
        <FooterColumn>
          {
            settingsPairs && settingsPairs.facebook ? (
                <a
                    className="footerLink"
                    href={settingsPairs.facebook.value}
                    title={`Facebook ${settingsPairs.name.value}`}
                    target="_blank"
                    rel="noreferrer">
                  Facebook
                </a>
            ) : ''
          }
          {
            settingsPairs && settingsPairs.instagram ? (
                <a
                    className="footerLink"
                    href={settingsPairs.instagram.value}
                    title={`Instagram ${settingsPairs.name.value}`}
                    target="_blank"
                    rel="noreferrer"
                >
                  Instagram
                </a>
            ) : ''
          }
        </FooterColumn>
        <FooterColumn>
          <FooterLine><b>{settingsPairs.name.value}</b></FooterLine>
          <FooterLine>
            {settingsPairs.street.value} {settingsPairs.houseNumber.value}<br />
            {settingsPairs.zipCode.value} {settingsPairs.city.value}
          </FooterLine>
          <FooterLineWithSpace>
            <a href={`tel:${settingsPairs.phone.value}`} title={`Bel ${settingsPairs.phone.value}`}>
              {settingsPairs.phone.value}
            </a>
          </FooterLineWithSpace>
          <FooterLine>
            <a
              href={`mailto:${settingsPairs.fromEmail.value}`}
              title={`Stuur een email naar ${settingsPairs.fromEmail.value}`}
            >
              {settingsPairs.fromEmail.value}
            </a>
          </FooterLine>
        </FooterColumn>
      </FooterColumns>
      <CopyRight />
    </Footer>
  );
}
