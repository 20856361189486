import React from 'react';
import styled from 'styled-components';

const MenuStyles = styled.div`
  height: 4rem;
  width: 4rem;
  display: flex;
  flex-direction: column;
  padding-right: var(--spacing-m);
  align-items: center;
  justify-content: center;
  background: #FFF;;
  position: relative;
  z-index: 4;
  position: absolute;
  right: 0;
  bottom: 0;

  @media (min-width: 520px) {
    display: none;
  }

  .menu {
    margin-top: 0.5rem;
    margin-left: 1rem;

    .barFirst,
    .barSecond,
    .barThird {
      background-color: var(--primary-color);
      height: 0.25rem;
      margin-bottom: 0.6rem;
      border: 0;
      transition: 400ms;
    }

    .barFirst {
      width: 3rem;
    }

    .barSecond {
      margin-left: 1rem;
      width: 2rem;
    }

    .barThird {
      width: 3rem;
    }
  }

  .menu:hover {
    cursor: pointer;

    .barFirst {
      opacity: 0.7;
      margin-left: 0;
      width: 3rem;
      transition: 400ms;
    }
    .barSecond {
      opacity: 0.7;
    }
    .barThird {
      opacity: 0.7;
      width: 3rem;
      transition: 400ms;
    }
  }
`;

export default function MenuHamburger({ visible, setVisible }) {
  return (
    <MenuStyles>
      <div
        onClick={() => {
          setVisible(!visible);
        }}
        className="menu"
      >
        <div className="barFirst" />
        <div className="barSecond" />
        <div className="barThird" />
      </div>
    </MenuStyles>
  );
}
