import React from 'react';
import styled from 'styled-components';
import GlobalStyles from '../../styles/GlobalStyles';
import Header from './Header';
import Footer from '../../styles/ContactFooter';
import MobileMenu from '../MobileMenu';

const Main = styled.div`
  margin: 0 auto;
  font-family: var(--font-family);
  font-weight: 300;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export default function Layout({ children, uri }) {
  console.warn(children);
  const bodyClassName = !uri
    ? 'page-404'
    : uri.replace('/', '') !== 'home'
    ? 'page'
    : 'home';
  return (
    <div className={bodyClassName}>
      <GlobalStyles />
      <Header uri={uri} />
      <ContentWrapper>
        <Main>{children}</Main>
      </ContentWrapper>
      <Footer />
      <MobileMenu />
    </div>
  );
}
