import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import MenuHamburger from './MenuHamburger';
import Logo from '../styles/Logo';

const MobileMenuContainer = styled.div`
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--primary-color);
  color: #FFF;
  justify-content: center;
  align-items: center;
  z-index: 5;

  &.visible {
    display: flex;
  }
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .mobileNavigationItem {
    font-family: var(--font-small);
    font-size: 1.5rem;
    font-weight: normal;
    color: #FFF;
    text-decoration: none;
    margin: var(--spacing-m) 0;
  }
`;

export default function Menu() {
  const [visible, setVisible] = useState(false);
  const currentClassName = 'mobileNavigationItem';
  return (
    <>
      <MenuHamburger visible={visible} setVisible={setVisible} />
      <MobileMenuContainer className={visible ? 'visible' : ''}>
        <Navigation>
          <Link
            to="/"
            onClick={() => setVisible(!visible)}
            className={currentClassName}
          >
            Home
          </Link>
          <Link
            to="/het-bedrijf"
            onClick={() => setVisible(!visible)}
            className={currentClassName}
          >
            Het bedrijf
          </Link>
          <Link
            to="/projecten"
            onClick={() => setVisible(!visible)}
            className={currentClassName}
          >
            Projecten
          </Link>
          <Link
            to="/contact"
            onClick={() => setVisible(!visible)}
            className={currentClassName}
          >
            Contact
          </Link>
          <Link
            to="/"
            onClick={() => setVisible(!visible)}
            className={`${currentClassName} logo`}
          >
            <Logo />
          </Link>
        </Navigation>
      </MobileMenuContainer>
    </>
  );
}
