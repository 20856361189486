import React from 'react';
import styled from 'styled-components';

const CopyRightStyles = styled.div`
  width: 100%;
  display: flex;
  font-family: var(--font-small);
  font-weight: 300;
  color: #FFF;
  font-size: 0.7rem;
`;

export default function CopyRight() {
  const currentDate = new Date().getFullYear();
  return (
    <CopyRightStyles>&copy; Chrisbakkerdak.nl {`${currentDate}`}</CopyRightStyles>
  );
}
