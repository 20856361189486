import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: var(--spacing-l);
`;

const MenuWrapper = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  .navigationItem {
    font-size: 1.3rem;
    font-family: var(--font-small);
    margin-right: var(--spacing-xl);
    margin-bottom: var(--spacing-l);
    margin-top: vaR(--spacing-l);
    font-weight: 600;
    text-decoration: none;
    color: #FFF;
    transition: 0.6s;
  }

  .navigationItem:hover {
    color: #FFF;
  }
`;

export default function Menu() {
  const currentClassName = 'navigationItem';
  return (
    <Wrapper>
      <MenuWrapper>
        <Link to="/" className={currentClassName}>
          Home
        </Link>
        <Link to="/het-bedrijf" className={currentClassName}>
          Het bedrijf
        </Link>
        <Link to="/projecten" className={currentClassName}>
          Projecten
        </Link>
        <Link to="/contact" className={currentClassName}>
          Contact
        </Link>
      </MenuWrapper>
    </Wrapper>
  );
}
