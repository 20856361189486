import { createGlobalStyle } from 'styled-components';
import backgroundImage from '../../static/404-bg.png';

const GlobalStyles = createGlobalStyle`
    :root {
        --primary-color: #1A4799;
        --primary-color-light: #8EB699;
        --secondary-color: #00B5F7;
        --secondary-color-light: #f4447c;
        --thertary-color: #260C47;
        --font-color: #13243a;
        --font-family: 'klinic_slab';
        --black: #000;
        --white: #FFF;
       
        --spacing-xs: 0.25rem;
        --spacing-s: 0.5rem;
        --spacing-m: 1rem;
        --spacing-l: 2rem;
        --spacing-xl: 4rem;
        --spacing-xxl: 8rem;
        --negative-spacing-m: -1rem;
        --negative-spacing-l: -2rem;
        
        --font-small: poppins, sans-serif;
        --font-large: futura-pt-condensed, sans-serif;
    }
    
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
      
    body, html {
       margin:0;
       padding:0;
       overflow: auto;
       font-weight: 100%;
       line-height: 1.3;
       box-sizing: border-box;
       width: 100%;
       height: 100%;
    }
    
    .page {
        background: var(--white);
    }
    
    .imageSpacer + .callToAction {
        margin-top: -19rem;
        
        @media only screen 
          and (min-device-width: 320px) 
          and (max-device-width: 480px)
          and (-webkit-min-device-pixel-ratio: 2) {
            margin-top: var(--spacing-m);
        }
    }
    
    .navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 4;
        width: 100%;
        flex-direction: column;
    }
    
    .page .navigation .navigationItem {
        color: #FFF;
    }
    
    .page-404 {
        min-height: 80vh;
        background-image: url('${backgroundImage}');
        background-color: rgba(38, 12, 71, 0.8);
        background-size: cover;
        background-blend-mode: multiply;
    }
    
    @media only screen 
      and (min-device-width: 320px) 
      and (max-device-width: 480px)
      and (-webkit-min-device-pixel-ratio: 2) {
        .page .navigation {
            padding-right: 0;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            
            
        }  
        .navigation .navigationItem {
            display: none;
        }
    }
    
`;

export default GlobalStyles;
