import React from 'react';
import DefaultHeader from './../DefaultHeader';
import MobileMenu from './../MobileMenu';

export default function Header(props) {
  const uri = !props.uri ? '404' : props.uri.replace('/', '');

  return (
    <div className={`navigation ${uri}`}>
      <DefaultHeader />
      <MobileMenu />
    </div>
  );
}
