import React from 'react';
import styled from 'styled-components';
import logoImage from './../assets/images/logo-chrisbakkerdak.png';
import Menu from "./Menu";
import {graphql, Link, useStaticQuery} from "gatsby";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background: var(--primary-color);
  width: 100%;
  padding: var(--spacing-m);
  margin-bottom: 0;
  
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) { 
    padding: var(--spacing-s);
  }
`;

const BlockLogo = styled.div`
  width: 16rem;
  background: url(${logoImage}) no-repeat center center;
  background-size: contain;
  height: 6rem;
  
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) { 
    height: 3rem;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  clear: both;
  padding: var(--spacing-s);
  
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
      position: fixed;
      top: 0;
      background: #FFF;
  }
`;

const EmailContainer = styled.div`
  font-size: 1rem;
  font-family: var(--font-small);
  color: var(--black);
  margin-right: var(--spacing-m);
  
  a {
    color: var(--font-color);
    text-decoration: none;
    
    &:hover {
        text-decoration: underline;
    }
  }
`;

const settingToValueMap = (settings) => {
    return settings.reduce((settingsKeyValuePair, setting) => {
        settingsKeyValuePair[setting.title] = {
            id: setting.id,
            value: setting.value
        }
        return settingsKeyValuePair;
    }, {});
}

export default function DefaultHeader() {
    const { settings } = useStaticQuery(graphql`
        query headerSettings {
            settings: allSanitySettings {
              nodes {
                title
                value
                id
              }
            }
        }
  `);
  const settingsPairs = settingToValueMap(settings.nodes);
  return (<>
    <InfoContainer>
        <EmailContainer>
            <a href={`mailto:${settingsPairs.fromEmail.value}`} title={`Mail ${settingsPairs.fromEmail.value}`}>
                {settingsPairs.fromEmail.value}
            </a>
        </EmailContainer>
        <EmailContainer>
            <a href={`tel:${settingsPairs.phone.value}`} title={`Bel ${settingsPairs.phone.value}`}>
                {settingsPairs.phone.value}
            </a>
        </EmailContainer>
    </InfoContainer>
    <Container>
        <Link to={'/'} title={'Home'}>
            <BlockLogo />
        </Link>
      <Menu />
    </Container>
  </>);
}
